<template>
    <div class="chart">
        <apexchart type="line" :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
  /* eslint-disable */
  import VueApexCharts from "vue3-apexcharts";
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: null,
      },
    },
    computed: {
      series() {
        return this.chartData && this.chartData.data ? this.chartData.data : [];
      },
      maxYValue() {
        let maxVal = 0;
        if (this.chartData && this.chartData.data) {
          this.chartData.data.forEach(dataset => {
            maxVal = Math.max(maxVal, ...dataset.data);
          });
        }
        return maxVal * 1.1; // Increase by 10%
      },
      options() {
        return {
            chart: {
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: true
              },
            animations: {
            enabled: false
          }
          },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Peso por baia',
              align: 'left'
            },
          xaxis: {
            categories: this.chartData.labels,
            title: {
                text: 'Idade'
              },
            tickAmount: 35
            },
            yaxis: {
              title: {
                text: 'Peso'
              },
              min: 15,
              max: this.maxYValue, // Set dynamically
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: false,
              offsetY: -25,
              offsetX: -50
            }
          }
    }
  }
  };
  </script>
  <style scoped>
  .chart {
    border: 2px solid green;
    margin-bottom: 1em;
  }
  </style>