<template>
    <div class="header">
      <img src="@/assets/pecsmart.png" alt="PecSmart Logo" class="logo">
      <h1><slot /></h1>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  export default {
    name: 'Header',
  }
  </script>
  
  <style scoped>
  .header {
    display: flex;
    align-items: center;
    background-color: green;
    color: white;
    padding: 1em;
    border-radius: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 1em;
  }
  </style>
  