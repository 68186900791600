<template>
  <div class="sidebar">
    <div class="logo-container">
      <img src="@/assets/pecsmart.png" alt="PecSmart Logo" class="logo">
    </div>
    <ul>
      <li><button @click="$router.push('/charts')"><font-awesome-icon icon="chart-line" /> Último Peso Por Baia</button></li>
      <li><button @click="$router.push('/weights')"><font-awesome-icon icon="weight" /> Relatório de Pesos</button></li>
      <li><button @click="downloadCsv"><font-awesome-icon icon="fa-download" /> Download CSV</button></li>
      <li><button @click="downloadExcel"><font-awesome-icon icon="fa-table" /> Download Excel</button></li>
    </ul>
  </div>
</template>


<script>
export default {
  name: 'SideBar',
  methods: {
    downloadCsv() {
      window.location.href = "https://camv3api.pecsmart.cloud/get_data_csv";
    },
    downloadExcel() {
      window.location.href = "https://camv3api.pecsmart.cloud/get_data_excel";
    }
  }
};
</script>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  padding: 1em;
}
.logo {
  width: 50px;
  height: 50px;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh; /* viewport height */
  background-color: green;
  color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.1);
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 1em;
  padding-top: 1em;
}

.sidebar button {
  all: unset;
  cursor: pointer;
  color: white;
  background-color: black;
  padding: 3em;
  width: 80%;
  text-align: left;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sidebar button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
}

.sidebar button:active {
  transform: translateY(0px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

</style>
