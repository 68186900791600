<template>
    <div class="chart">
        <apexchart type="bar" :options="options" :series="series"></apexchart>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import VueApexCharts from "vue3-apexcharts";
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: null,
      },
    },
    computed: {
      series() {
        return this.chartData && this.chartData.data ? this.chartData.data : [];
      },
      options() {
        return {
          chart: {
            id: "basic-bar",
          },
          xaxis: {
            categories: this.chartData && this.chartData.labels ? this.chartData.labels : [],
          },
        };
      },
    },
  };
  </script>
  <style scoped>
  .chart {
    border: 2px solid green;
    margin-bottom: 1em;
  }
  </style>