<template>
  <div class="page">
    <Header>Último Peso por Baia</Header>
    <div class="batch-navigation">
      <button @click="changeBatch(-1)"><font-awesome-icon icon="arrow-left" /></button>
      <span>{{ currentBatch }}</span>
      <button @click="changeBatch(1)"><font-awesome-icon icon="arrow-right" /></button>
    </div>
    <div class="chart-container">
      <div v-if="!renderChart" class="loading-container">
        <font-awesome-icon icon="spinner" spin />
        <p>Por favor espere enquanto os dados do lote são carregados</p>
      </div>
      <div v-else-if="error" class="error-container">
        <font-awesome-icon icon="exclamation-triangle" />
        <p>{{ errorMessage }}</p>
      </div>
      <div v-else>
        <div v-if="loadingChartData" class="loading-chart-container">
          <font-awesome-icon icon="spinner" spin />
          <p>Por favor espere enquanto os dados do gráfico são carregados</p>
        </div>
        <bar-chart v-else :key="chartData" :chartData="chartData" :options="options"></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import BarChart from "@/components/BarChart.vue";
import axios from "axios";
import store from '@/store'

export default {
  name: 'ChartsPage',
  components: {
    BarChart,
    Header
  },
  data() {
    return {
      chartData: null,
      renderChart: false,
      error: false,
      errorMessage: '',
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      currentBatchIndex: 0,
      batches: store.getters.currentUserBatches,
      loadingChartData: false
    };
  },
  computed: {
    currentBatch() {
      return this.batches[this.currentBatchIndex];
    }
  },
  mounted() {
    this.fetchChartData();
  },
  methods: {
    async fetchChartData() {
      try {
        this.renderChart = false;
        this.loadingChartData = true;
        const response = await axios.get('https://camv3api.pecsmart.cloud/bar-charts-data', {
          params: {
            username: store.getters.currentUserName,
            batch_pk: this.currentBatch
          }
        });
        this.chartData = response.data;
        this.renderChart = true;
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data.detail) {
          this.errorMessage = 'Erro ao carregar os dados do lote'; 
        } else {
          this.errorMessage = 'Erro ao carregar os dados do lote';
        }
        this.error = true;
      } finally {
        this.loadingChartData = false;
      }
    },
    changeBatch(step) {
      this.currentBatchIndex += step;
      if (this.currentBatchIndex < 0) {
        this.currentBatchIndex = this.batches.length - 1;
      } else if (this.currentBatchIndex >= this.batches.length) {
        this.currentBatchIndex = 0;
      }
      this.fetchChartData();
    }
  }
};
</script>

<style scoped>
.page {
  background-color: white;
  padding: 2em;
}

.page h1 {
  background-color: green;
  color: white;
  padding: 1em;
}

.batch-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.batch-navigation button {
  all: unset;
  cursor: pointer;
  color: white;
  background-color: black;
  padding: 0.5em;
  border-radius: 50%;
  margin: 0 0.5em;
}

.batch-navigation span {
  font-size: 1.2em;
  font-weight: bold;
}

.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-container p,
.error-container p {
  margin-top: 1em;
}

.loading-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1em;
  margin-top: 1em;
}
</style>