import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartLine, faWeight, faUser, faKey, faChartBar, faDownload, faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faSpinner, faExclamationTriangle, faTable } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faChartLine, faUser, faKey, faChartBar, faWeight, faDownload, faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faSpinner, faExclamationTriangle, faTable )

const app = createApp(App)
app.use(router)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')

document.body.style.color = 'black';
