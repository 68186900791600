<template>
  <div id="app">
    <SideBar v-if="isLoggedIn" />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from './components/SideBar.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    SideBar
  },
  computed: {
    isLoggedIn() {
      const store = useStore()
      return computed(() => store.getters.isLoggedIn)
    }
  }
}
</script>


<style>
.content {
  margin-left: 200px; /* Same width as the sidebar */
}
button {
  padding: 10px 20px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
}

button:active {
  transform: translateY(0px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

</style>