import { createStore } from 'vuex'

const store = createStore({
  state: {
    loggedIn: localStorage.getItem('loggedIn') === 'true' || false,
    userName: localStorage.getItem('userName'),
    userBatches: JSON.parse(localStorage.getItem('userBatches')) || [],
    userCurrentBatch: localStorage.getItem('userCurrentBatch')
  },
  mutations: {
    setLoggedIn(state, value) {
      state.loggedIn = value
      localStorage.setItem('loggedIn', value.toString())
    },
    setUserName(state, value) {
      state.userName = value
      localStorage.setItem('userName', value)
    },
    setUserBatches(state, value) {
      state.userBatches = value
      localStorage.setItem('userBatches', JSON.stringify(value))
    },
    setUserCurrentBatch(state, value) {
      state.userCurrentBatch = value
      localStorage.setItem('userCurrentBatch', value)
    }
  },
  actions: {
    setLoggedIn({ commit }, value) {
      commit('setLoggedIn', value)
    },
    setUserName({ commit }, value) {
      commit('setUserName', value)
    },
    setUserBatches({ commit }, value) {
      commit('setUserBatches', value)
    },
    setUserCurrentBatch({ commit }, value) {
      commit('setUserCurrentBatch', value)
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.loggedIn
    },
    currentUserName(state) {
      return state.userName
    },
    currentUserBatches(state) {
      return state.userBatches
    },
    currentUserCurrentBatch(state) {
      return state.userCurrentBatch
    }
  }
})

export default store
