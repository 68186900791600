<template>
  <div class="page">
    <Header>Relatório de Pesos - Batch {{ currentBatch }}</Header>
    <div class="batch-navigation">
      <button @click="changeBatch(-1)"><font-awesome-icon icon="arrow-left" /></button>
      <span>{{ currentBatch }}</span>
      <button @click="changeBatch(1)"><font-awesome-icon icon="arrow-right" /></button>
    </div>
    <div class="chart-container">
      <div v-if="!renderChart" class="loading-container">
        <font-awesome-icon icon="spinner" spin />
        <p class="header">Por favor espere enquanto os dados do lote são carregados</p>
      </div>
      <div v-else-if="error" class="error-container">
        <font-awesome-icon icon="exclamation-triangle" />
        <p>{{ errorMessage }}</p>
      </div>
      <div v-else>
        <div v-if="loadingChartData" class="loading-chart-container">
          <font-awesome-icon icon="spinner" spin />
          <p>Por favor espere enquanto os dados do gráfico são carregados</p>
        </div>
        <line-chart v-else :key="chartData" :chartData="chartData" :options="options"></line-chart>
      </div>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Nº da Baia</th>
            <th>Sexo</th>
            <th>Tratamento</th>
            <th>Idade</th>
            <th>Data</th>
            <th>Último Peso</th>
            <th>GPD</th>
            <th>GPD Médio 7 dias</th>
            <th>GPD Médio 14 dias</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in tableData" :key="row.pen_number">
            <td>{{ row.pen_number }}</td>
            <td>{{ row.sex }}</td>
            <td>{{ row.treatment }}</td>
            <td>{{ row.age }}</td>
            <td>{{ row.date }}</td>
            <td>{{ row.weight }}</td>
            <td>{{ row.gpd }}</td>
            <td>{{ row.weight7 }}</td>
            <td>{{ row.weight14 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart.vue";
import Header from "@/components/Header.vue";
import axios from "axios";
import store from '@/store'

export default {
  name: 'WeightsPage',
  components: {
    LineChart,
    Header
  },
  data() {
    return {
      chartData: null,
      renderChart: false,
      error: false,
      errorMessage: '',
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      tableData: null,
      currentBatchIndex: 0,
      batches: store.getters.currentUserBatches,
      loadingChartData: false
    };
  },
  computed: {
    currentBatch() {
      return this.batches[this.currentBatchIndex];
    }
  },
  mounted() {
    this.fetchChartData();
    this.fetchTableData();
  },
  methods: {
    async fetchChartData() {
      try {
        this.renderChart = false;
        this.loadingChartData = true;
        const response = await axios.get('https://camv3api.pecsmart.cloud/charts-data', {
          params: {
            username: store.getters.currentUserName,
            batch_pk: this.currentBatch
          }
        });
        this.chartData = response.data;
        this.renderChart = true;
      } catch (error) {
        console.error(error);
        if (error.response) {
          if (error.response.status === 404) {
            this.errorMessage = 'The requested batch does not belong to the current user.';
          } else {
            this.errorMessage = 'Unexpected error occurred while fetching chart data.';
          }
        } else {
          this.errorMessage = 'Network error. Please check your connection and try again.';
        }
        this.error = true;
      } finally {
        this.loadingChartData = false;
      }
    },
    async fetchTableData() {
      try {
        const response = await axios.get('https://camv3api.pecsmart.cloud/weights-data', {
          params: {
            username: store.getters.currentUserName,
            batch_pk: this.currentBatch
          }
        });
        if (response.data) {
          // Filtering out any null or undefined values
          this.tableData = response.data;
        } else {
          // Handle case where data is not as expected
          console.error('Unexpected response data format:', response.data);
          throw new Error('Unexpected response data format');
        }
        this.renderChart = true;
      } catch (error) {
        console.error(error);
        this.error = true;
        if (error.response) {
          if (error.response.status === 404) {
            this.errorMessage = 'The requested batch does not belong to the current user.';
          } else {
            this.errorMessage = 'Unexpected error occurred while fetching table data.';
          }
        } else {
          this.errorMessage = 'Network error. Please check your connection and try again.';
        }
      }
    },
    changeBatch(step) {
      try {
        this.currentBatchIndex += step;
        if (this.currentBatchIndex < 0) {
          this.currentBatchIndex = this.batches.length - 1;
        } else if (this.currentBatchIndex >= this.batches.length) {
          this.currentBatchIndex = 0;
        }
        this.fetchChartData();
        this.fetchTableData();
      } catch (error) {
        console.error(error);
        this.error = true;
        this.errorMessage = 'An error occurred while changing the batch.';
      }
    }
  }
};
</script>

<style scoped>
.page {
  background-color: white;
  padding: 2em;
}

.page h1 {
  background-color: green;
  color: white;
  padding: 1em;
}

.batch-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  padding: 2em;
}

.batch-navigation button {
  all: unset;
  cursor: pointer;
  color: white;
  background-color: black;
  padding: 0.5em;
  border-radius: 50%;
  margin: 0 0.5em;
}

.batch-navigation span {
  font-size: 1.2em;
  font-weight: bold;
}

.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-container p,
.error-container p {
  margin-top: 1em;
}
.header {
    display: flex;
    align-items: center;
    background-color: green;
    color: white;
    padding: 1em;
    border-radius: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
.loading-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1em;
  margin-top: 1em;
}
.chart-container, .table-container {
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.chart-container:hover, .table-container:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
}

  table {
    width: 100%;
    border-collapse: collapse;
  }
  th {
    border: 1px solid black;
    padding: 10px;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
  }
  td {
    border: none;
    padding: 10px 15px;
    text-align: center;
  }
  tr:hover {
  background-color: rgba(75, 192, 192, 0.2);
}
tr:nth-child(odd) {
  background-color: #f2f2f2;
}

  .page {
    background-color: white;
    padding: 2em;
  }

  .page h1 {
    background-color: green;
    color: white;
    padding: 1em;
  }
</style>
