<template>
  <div class="login-page">
    <div class="logo-container">
      <img class="logo" src="@/assets/pecsmart.png" alt="PEC Smart Logo" />
    </div>
    <form @submit.prevent="login">
      <div class="input-group">
        <font-awesome-icon icon="user" class="icon" />
        <input v-model="username" placeholder="Username" />
      </div>
      <div class="input-group">
        <font-awesome-icon icon="key" class="icon" />
        <input v-model="password" type="password" placeholder="Password" />
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import store from '@/store'

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.get('https://camv3api.pecsmart.cloud/auth_user', {
          params: {
            username: this.username,
            password: this.password
          }
        });

        const { data } = response;
        if (data === true) {
          const batches = await axios.get('https://camv3api.pecsmart.cloud/get_user_batches', {
            params: {
              username: this.username
            }
          });
          store.dispatch('setUserName', this.username)
          store.dispatch('setUserBatches', batches.data)
          store.dispatch('setLoggedIn', true)
          this.$router.push('/charts')
        } else {
          alert('Invalid Login!')
          throw new Error('Invalid credentials');
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }
};
</script>

<style scoped>
.login-page {
  background: url(../assets/login-fundo.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.logo {
  width: 150px;
  height: 150px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

.logo:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.login-page form {
  background-color: white;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.login-page form:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.login-page input,
.login-page button {
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 15px;
  padding-top: 0.5em;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.input-group .icon {
  margin-right: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  margin-top: -0.5em;
}
</style>